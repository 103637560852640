<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Confirmed Opt In</h1>
                <button class="close_btn left_out" @click="closeModel"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="email-confirm-opt-in">
                <div class="modal_body">
                    <div class="edit_section mt-2">
                        <div class="setting_wpr">
                            <label for="confirmedOptin" class="switch_option capsule_btn">
                                <h5>Status</h5>
                                <input type="checkbox" id="confirmedOptin" v-model="form.is_confirm_opt" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-if="!form.is_confirm_opt">
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Access Key</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.access_key }">
                                            <Field autocomplete="off" type="text" name="access_key" v-model="form.access_key" rules="required" label="access key" />
                                        </div>
                                        <ErrorMessage name="access_key" class="text-danger"/>
                                    </div>
                                </div>
                            </div>
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Mobile number</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.mobile_no }">
                                            <Field autocomplete="off" type="text" name="mobile_no" v-model="form.mobile_no" placeholder="ex. +1(999)999-999" labal="mobile number" />
                                        </div>
                                        <ErrorMessage name="mobile_no" class="text-danger"/>
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr mt-2">
                                <button :disabled="accessKeyLoader" type="button" class="btn save_btn" @click="handleRequestAccessKey()"><i class="fa fa-spinner fa-spin" v-if="accessKeyLoader"></i> {{ accessKeyLoader ? 'Sending' : 'Request Access Key' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModel()">Cancel</button>
                <button type="button" :disabled="loader" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    export default {
        name: 'Confirm Optin',

        data() {
            return {
                form: {
                    is_confirm_opt: '',
                    access_key: '',
                    mobile_no: '',
                },
            }
        },

        props: {
            modelValue: Boolean,
        },

        components: {
            Field,
            Form,
            ErrorMessage
        },

        computed: mapState({
            user: state => state.authModule.user,
            loader: state => state.adminSettingModule.confirmedOptinLoader,
            accessKeyLoader: state => state.adminSettingModule.accessKeyLoader,
        }),

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.resetForm();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        created () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                sendAccessKey: 'adminSettingModule/sendConfOptinAccessKey',
                updateConfirmOptin: 'adminSettingModule/updateConfirmOptin',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            handleSubmitForm () {
                const vm = this;

                const Optinform = vm.$refs['email-confirm-opt-in'];

                Optinform.validate().then((result) => {
                    if (result.valid) {
                        let form = vm.form && vm.form.is_confirm_opt ? { is_confirm_opt: vm.form.is_confirm_opt } : vm.form;
                        form.setFieldError = Optinform.setFieldError;
                        form.resetForm = Optinform.resetForm;

                        vm.handleUpdateConfirmOptin(form);
                    }
                })
            },

            handleUpdateConfirmOptin (form) {
                const vm = this;

                vm.updateConfirmOptin(form).then((result) => {
                    if (result) {
                        vm.closeModel();
                        form.resetForm();
                    }
                });
            },

            handleRequestAccessKey () {
                const vm = this;

                vm.sendAccessKey(vm.form);
            },

            resetForm () {
                const vm = this;

                let intervalCount = 0;

                const checkUser = setInterval(() => {
                    if (vm.user || intervalCount == 50) {
                        clearInterval(checkUser);

                        vm.form  = {
                            access_key: '',
                            is_confirm_opt: vm.user && vm.user.is_confirm_opt ? 1 : 0,
                            mobile_no: vm.user && vm.user.contactno ? vm.user.contactno : '',
                        };
                    }

                    intervalCount++;
                }, 100);
            }
        }
    }
</script>
